@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;800&family=Outfit:wght@400&display=swap);
body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

.centered {
  text-align: center !important;
}

.ui.cards>.card {
  height:100%;
}

h3 {
  color:#362F52;
}

.ui.label {
  background-color:#c17900;
  color:white;
}

.item a {
  color: #362F52 !important;
}
.item a:hover {
  -webkit-text-decoration:2px solid #E6B34B underline;
          text-decoration:2px solid #E6B34B underline;

}

.ui.card>.content>.header, .ui.cards>.card>.content>.header {
  font-family:"Nunito";
}

p {
  font-family: 'Outfit', sans-serif;
  font-size: 16px;
  color:black;
}

.reese {
  width: 65%;
  margin: 0 auto;
  display: block;
}

h1 {
  color: #f9a825 !important;
  font-weight:700;
}

h1 a {
  color: #E6B34B !important;
  font-family: "Nunito";
  font-size: 65px;
  line-height: 64px;

}

.logo {
  width: 30% !important;
}

.reeses {
  width: 40%;
  float: right
}

.reese {
  width: 40%;
  float: left
}

.crown {
  width: 40%;
  margin: 0 auto;
  display: block;
}

.tiles {
  margin-top: 150px !important;
}

.icCopy {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  color: #3A3A3A !important;
  margin-bottom: 40px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.explainwave {
  /* margin-top:-240px; */
  margin-top: 90px;
}

.explainer {
  background-color: white;
  margin-top: -60px;
}

.explainer .ui.grid>.row {
  padding: 30px;
  border-top: 1px solid #eee;
}

.test {
  margin-top: -390px;
}

.wave-container {
  margin-top: -110px;
}

.transitions-item {
  overflow: hidden;
  width: 100%;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 4em;
  font-family: "Playfair Display", sans-serif;
  font-weight: 400;
  will-change: transform, opacity, height;
  white-space: nowrap;
  cursor: pointer;
  line-height: 90px;
}

.list {
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
    segoe ui, arial, sans-serif;
  width: 100%;
  height: 100%;
}

.list>div {
  position: absolute;
  will-change: transform, width, height, opacity;
  padding: 15px;
}

.list>div>div {
  position: relative;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  border-radius: 4px;
  box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);
}

.ui.cards {
  margin-top:-40px;
}

.ui.button:hover {
  background-color: #DBC9CB;
color:#362F52;
}

/* .ui.button a:hover */

a {
  color: #black !important;
}

a:hover {
  color: #B09FED;

}
.heads {
  text-align: center !important;
}

.nice {
  margin-top: 40px !important;
  padding: 30px !important;
}

.niceImg {
  width: 70%;
}

.explainerButton {
  display: block !important;
  margin: 0 auto !important;
}

.type {
  padding: 40px !important;
}

.imageCopy {
  background-color: #F6D6CF;
}

.opening {
  margin-bottom: 30px !important;
}

.four {
  margin-top: -100px;
}

.five {
  margin-top: -100px;
}

/* .port {
  margin-top: -10px;
  padding: 40px !important;
  background-color: #FFF5EC;
} */

.experience {
  padding: 40px !important;
  background-color: white;
}

.ui.menu {
  border: none !important;
}

.example-appear {
  opacity: 0.03;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity .5s ease-in;
}

a {
  color: rgba(0, 0, 0, .87)
}

.ui.button {
  background-color: #ffd95a;
  color:#666;
}

/* .jumbotron {
  background-color: #F9EDED !important;
} */

.ui.huge.header {
  font-size: 75px;
  font-size: 300 !important;
  margin-top: 120px;
}

@media(max-width:767px) {
  .home {
    width: 40% !important;
  }

  .testing {
    position:relative !important;
  }

  .crown {
    width: 100%;
  }

  .ui.huge.header {
    font-size: 55px;
    font-size: 300 !important;
    margin-top: 0px;
  }

  .niceImg, .imgNice2 {
    display: none !important;
  }

  .transitions-item {
    font-size: 40px !important;
    font-weight: 500;
    line-height: 30px;
  }

  .icCopy {
    margin-top: -90px !important;
  }
}

